<template>
  <div id="order">
    <p class="p-type">问题类型</p>
    <van-dropdown-menu>
      <van-dropdown-item v-model="value1" :options="option1" />
    </van-dropdown-menu>
    <div class="order-describe">
      <p><span>{{write_num}}</span>/120</p>
      <div class="content" :style="V3content">
        <textarea v-model.trim="text" v-on:input="input" maxlength="120" cols="30" rows="6" placeholder="详细的问题描述往往是非常有必要的哦~"></textarea>
      </div>
    </div>
    <div class="order-pic">
      <div class="order-pic-top">
        <p>添加图片(选填）</p>
      </div>

      <div class="order-pic-bottom">
        <van-uploader v-model="fileList" multiple :max-count="3" :max-size="2000 * 1024" @oversize="onOversize" accept="image/png, image/jpeg" />
      </div>
    </div>
    <div class="order-submit" :style="newStyle">
      <span v-on:click="submit()">提交</span>
    </div>
    <Popout v-show="isPrpout" v-on:confirm="onConfirm" v-on:cancel="onCancel" content="描述不能为空!!" />
    <van-loading type="spinner" color="#00BB8C" v-show="loading" />
    <div v-show="showMask" class="mask" v-on:click="mask_close()">

    </div>
  </div>

</template>

<script>
import "@/styles/order.scss";
import Popout from './Alert.vue'
import { ref } from 'vue';
import { Toast } from 'vant';
export default {
  name: "Order",
  created () {
    // this.loading=true;
    // this.showMask=true;

  },
  mounted () {
    if (localStorage.getItem("version") == 3) {
      this.newStyle = "background: linear-gradient(310deg, #01E3CD 0%, #16A4CC 100%);"
      this.V3content = " background-color: #2F333F;"
      document.querySelector('.container').style.backgroundColor = "#23262F"
      document.body.style.backgroundColor = "#23262f"
    }
  },
  setup () {
    const value1 = ref(Number(localStorage.getItem('first_problem')));
    const arr = JSON.parse(localStorage.getItem('problem'));
    const fileList = ref([]);
    const onOversize = (file) => {
      Toast('文件大小不能超过 2M');
    };
    var option1 = [];
    arr.forEach((item, key) => {
      var newarr = new Object();
      newarr.text = item.name;
      newarr.value = key;
      newarr.id = item.id;
      option1.push(newarr)
    })
    return {
      value1,
      option1,
      fileList,
      onOversize,
    };
  },
  components: {
    Popout
  },
  data () {
    return {
      type: this.$router.currentRoute.value.query.type,
      write_num: '0',
      text: '',
      first_src: '',
      second_src: '',
      third_src: '',
      isPrpout: false,
      isUpload: false,
      falseContent: '',
      loading: false,
      showMask: false,
      newStyle: '',
      V3content: ''
    }
  },
  methods: {
    input () {
      this.write_num = this.text.length;
    },
    submit () {
      let formData = new FormData();
      formData.append("group", 'appeals');
      this.fileList.forEach((item) => {
        formData.append('files', item.file);
      })
      if (this.text.length === 0) {
        this.isPrpout = true;
      } else {
        this.loading = true;
        this.showMask = true;
        this.$request.request().addHeader('Content-Type', 'multipart/form-data').post('upload/images', formData).then((response) => {
          var url = null;
          var thumbs = null;
          if (response.data.status === 0) {
            url = response.data.recordset.urls;
            thumbs = response.data.recordset.thumbs;
          }
          this.$request.request(this.$member.token()).post('appeal', { content: this.text, pictures: url, thumbs: thumbs, question_id: this.option1[this.value1].id }).then((response) => {
            // this.$member.token()
            if (response.data.status === 0) {
              this.loading = false;
              this.showMask = false;
              Toast.loading({
                message: '提交成功!',
                forbidClick: true,
                overlay: true
              });
              setTimeout(function () {
                location.href = '/'
              }, 2000)
            } else {
              this.loading = false;
              this.showMask = false;
              Toast.loading({
                message: 'response.data.msg',
                forbidClick: true,
                overlay: true
              });
            }
          }).catch((error) => {
            this.loading = false;
            this.showMask = false;
            Toast.loading({
              message: '提交失败,请稍后再试',
              forbidClick: true,
              overlay: true
            });
          });
        });
      }
    },
    onConfirm (val) {
      this.isPrpout = false;
    },
    onCancel () {
      this.isPrpout = false;
    },
  },




}
</script>

<style scoped>
</style>