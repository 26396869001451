export default {
    index: {
        meta: {
            title: "我的工单",

               keywords:"666666666666",
               description:"888888888888888888888"

        },

    },
    order: {
        meta: {
            title: "提交工单"
        },

    },
    details: {
        meta: {
            title: "工单详情"
        },

    },
    alert: {
        meta: {
            title: "弹出层"
        },

    },
}