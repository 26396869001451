<template>
  <div id="detail" :style="windowContent">
    <p class="customer-qq" :style="windowQQ">您好，引力加速器为您服务。可添加Q群号：763279071</p>
    <span class="close" :style="windowClose" v-show="repair" v-on:click="close_repair()">关闭工单</span>
    <van-list loading-text="加载中..." v-model="loading" offset="50" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <div v-for="(item) in orderLogs.rows" v-bind:key="item.id">
        <div v-if="!item.is_comment" class="detail-user">
          <p>{{formatDate(item.created_at)}}</p>
          <div class="detail-user-content" :style="V3content">
            <p v-if="item.content">{{item.content}}
              <span v-if="item.ack===false&&item.has_error===false">发送中</span>
              <span class="error" v-else-if="item.ack===false&&item.has_error===true" v-on:click="resend(item.request_id)">重发</span>
            </p>
          </div>
          <div class="detail-user-img" v-for="(index) in item.thumbs" v-bind:key="index.id">
            <img :src="(index)" alt="" v-on:click="bigUri('user',item.pictures[0])">
            <span v-if="item.ack===false&&item.has_error===false">发送中</span>
            <span class="error" v-else-if="item.ack===false&&item.has_error===true">发送失败</span>
          </div>
        </div>
        <div v-else class="detail-customer">
          <p>{{formatDate(item.created_at)}}</p>
          <div class="detail-customer-content" :style="V3customer">
            <p v-if="item.content">{{item.content}}</p>
          </div>
          <div class="detail-customer-img" v-for="(index) in item.thumbs" v-bind:key="index.id">
            <img :src="(index)" alt="" v-on:click="bigUri('customer',item.pictures[0])">
          </div>
        </div>
      </div>
    </van-list>

    <div class="detail-write" v-show="Success">
      <div class="write">
        <textarea ref="textarea" placeholder="0/120" :style="V3content" v-model.trim="text" v-on:input="write_num()" class="write-input" maxlength="120" cols="20" rows="2"></textarea>
      </div>
      <!--      <div class="pic" v-on:click="addImg()">-->
      <!--        <img src="/images/pic2.png" alt="">-->
      <!--      </div>-->
      <van-uploader class="pic" v-model="fileList" :preview-image=false :max-size="2000 * 1024" :after-read="uploadImg" @oversize="onOversize" accept="image/png, image/jpeg">
        <img src="/images/pic2.png" alt="">
      </van-uploader>
      <div class="submit" :style="(color)" v-on:click="submit()">
        <span>提交</span>
      </div>
    </div>
    <!--    <input type="file" ref="filElem"  @change="uploadImg($event)" class="input_file">-->
    <Popout v-show="isPrpout" v-on:confirm="onConfirm" v-on:cancel="onCancel" content="最多上传3张图片!" />
    <Popout v-show="isFalse" v-on:confirm="onConfirm" v-on:cancel="onCancel" :content="(falseContent)" />
    <Popout v-show="isNum" v-on:confirm="onConfirm" v-on:cancel="onCancel" content="输入内容不能为空" />
    <Popout v-show="isSuccess" v-on:confirm="onConfirm" v-on:cancel="onCancel" content="提交成功!" />
    <Popout v-show="isSoved" v-on:confirm="onSuccess" v-on:cancel="onCancel" content="确定关闭当前工单!" />
    <Popout v-show="isFailed" v-on:confirm="onConfirm" v-on:cancel="onCancel" :content="(falseContent)" />
    <van-loading type="spinner" color="#00BB8C" v-show="home_loading" />
    <img :src="userBig" class="bigPic" alt="" v-show="bigPic">
    <img :src="customerBig" class="bigPic" alt="" v-show="bigPicCustome">
    <div v-show="showMask" class="mask" v-on:click="mask_close()">

    </div>
  </div>
</template>

<script>
import "@/styles/order_details.scss";
import Popout from './Alert.vue'
import { ref } from "vue";
import { Toast } from "vant";
export default {
  name: "Details",
  setup () {
    const fileList = ref([]);
    const onOversize = (file) => {
      Toast('文件大小不能超过 2M');
    };
    return {
      fileList,
      onOversize,
    };
  },
  created () {
    this.page = 0;
    this.onLoad();
    this.create_ws();
    if (Number(this.$route.query.status) === 4) {
      this.Success = false;
      this.repair = false;
    }
    if (navigator.userAgent.indexOf('Electron') > -1) {
      this.windowContent = 'margin-top:50px;';
      this.windowQQ = 'top:10%;';
      this.windowClose = 'top:15%;'
    }
  },
  mounted () {
    if (localStorage.getItem("version") == 3) {
      this.V3content = "background-color:#2F333F;"
      this.V3customer = "background: #16C7CF;"
      document.querySelector('.container').style.backgroundColor = "#23262F"
      document.body.style.backgroundColor = "#23262f"
    }
  },
  components: {
    Popout
  },
  data () {
    return {
      isUpload: false,
      imgUrl: [],
      orderLogs: { rows: [], page: 0 },
      picLogs: { rows: [] },
      loading: false,
      finished: false,
      page: 0,//页数
      order_total: '',
      isPrpout: false,
      text: '',
      isFalse: false,
      isSuccess: false,
      imgVlaue: [],
      falseContent: '',
      isNum: false,
      ws_url: 'wss://api.initap.com/appeal/chat',
      // ws_url: 'ws://192.168.50.93:8090/api/appeal/chat',
      lock: { reconnect: false },
      heart_beat_timer: null,
      socket: null,
      color: '',
      Success: true,
      home_loading: true,
      isSoved: false,
      isFailed: false,
      repair: true,
      timer: null,
      userBig: '',
      customerBig: '',
      showMask: false,
      bigPic: false,
      bigPicCustome: false,
      windowContent: '',
      windowQQ: '',
      windowClose: '',
      V3content: '',
      V3customer: ''
    }
  },
  methods: {
    resend (request_id) {
      this.orderLogs.rows.forEach((item, index) => {
        if (item.request_id === request_id) {
          this.socket.send(JSON.stringify({ type: 'data', request_id: request_id, data: JSON.stringify({ content: item.content, pictures: null, thumbs: null, id: this.$route.query.id }) }));
          item.has_error = false;
          this.orderLogs.rows.splice(index, 1, item)
        }
      })
      this.time_replace(request_id);
    },
    guid () {
      function S4 () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      }

      return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
    },
    create_ws () {
      this.socket = new WebSocket(this.ws_url);
      this.register_ws_handler()
    },
    register_ws_handler () {
      this.socket.onopen = () => {
        this.socket.send(JSON.stringify({
          type: "open",
          data: this.$member.token(),
        }))
        this.heart_beat()
      };
      this.socket.onmessage = (event) => {
        let data = JSON.parse(event.data);
        if (data.type === 'data') {
          if (data.code === 0) {
            this.orderLogs.rows.push(data.data);
            setTimeout(function () {
              var top = document.getElementById('detail');
              top.scrollTop = top.scrollHeight;
            }, 500)
          }
        } else if (data.type === "data_ack") {
          this.orderLogs.rows.forEach((item, index) => {
            if (Object.prototype.hasOwnProperty.call(item, "request_id") && item.request_id === data.request_id) {
              clearTimeout(this.timer);
              if (data.code === 0) {
                item.ack = true  //ack==false&&has_error=false发送中,ack==false&&has_error=true 重发
              } else {
                item.has_error = true
              }
              this.orderLogs.rows.splice(index, 1, item)
            }
          })
        }
      }
      // 连接关闭后响应
      this.socket.onclose = () => {
        //console.log("关闭连接");
        this.reconnect();//重连
      }
      this.socket.onerror = (e) => {
        // console.log(e)
        this.reconnect();//重连
      };
    },
    reconnect () {
      if (this.lock.reconnect) return;
      this.lock.reconnect = true;
      //没连接上会一直重连，设置延迟避免请求过多
      setTimeout(() => {
        this.create_ws();
        //console.log("正在重连，当前时间"+new Date())
        this.lock.reconnect = false;
      }, 5000); //这里设置重连间隔(ms)
    },
    heart_beat () {
      if (this.heart_beat_timer) {
        return
      }
      this.heart_beat_timer = setInterval(() => {
        if (this.socket) {
          this.socket.send(JSON.stringify({
            type: 'heart_beat',
            data: "ping"
          }))
        }
      }, 10000)
    },
    uploadImg () {
      this.isUpload = true;
      let file = this.fileList[0].file;
      var request_id = this.guid();
      var reader = new FileReader();
      reader.readAsDataURL(file);
      let that = this;
      reader.onload = function () {
        let formData = new FormData();
        formData.append("group", 'appeals');
        that.fileList.forEach((item) => {
          formData.append('files', item.file);
        })
        that.$request.request().addHeader('Content-Type', 'multipart/form-data').post('upload/images', formData).then((response) => {
          var url = null;
          var thumbs = null;
          var request_id = that.guid();
          if (response.data.status === 0) {
            url = response.data.recordset.urls;
            thumbs = response.data.recordset.thumbs;
          }
          that.socket.send(JSON.stringify({ type: 'data', request_id: request_id, data: JSON.stringify({ content: that.text, pictures: url, thumbs: thumbs, id: that.$route.query.id }) }));
          that.orderLogs.rows.push({
            content: "",
            thumbs: thumbs,
            pictures: url,
            created_at: Math.ceil((new Date()).getTime() / 1000),
            request_id: request_id,
            ack: false,
            has_error: false
          })
          that.time_replace(request_id);
          setTimeout(function () {
            var top = document.getElementById('detail');
            top.scrollTop = top.scrollHeight;
          }, 500)
          that.fileList = [];
        });
      }

    },

    formatDate (time) {
      let date = new Date(time * 1000);
      return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds()
    },
    // 点击确定隐藏
    onConfirm () {
      this.isPrpout = false;
      this.isFalse = false;
      this.isSuccess = false;
      this.isNum = false;
      this.isFailed = false;
    },
    onCancel () {
      this.isSoved = false;
      this.isPrpout = false;
      this.isFalse = false;
      this.isSuccess = false;
      this.isNum = false;
      this.isFailed = false;
    },
    onSuccess () {
      this.isSoved = false;
      this.$request.request(this.$member.token()).post('appeal/close', { id: this.$route.query.id }).then((response) => {
        if (response.data.status === 0) {
          this.$router.push({ path: '/' })
        } else {
          this.isFailed = true;
          this.falseContent = response.data.msg;
        }
      });
    },
    LoadOrderlogs (page, cb) {
      page = page === undefined ? 1 : page;
      let rows = 10;
      var order_id = this.$route.query.id;
      this.$request.request(this.$member.token()).get('appeal/comments', {
        id: order_id, page: page,
        row: rows, sort: 'updated_at'
      }).then((response) => {
        this.home_loading = false;
        this.order_total = response.data.recordset.total;
        if (response.data.recordset.page > this.orderLogs.page) {
          response.data.recordset.rows.forEach((item) => {
            // console.log(this.orderLogs.rows.reverse())
            this.orderLogs.rows.push(item)
          })
          this.orderLogs.total = response.data.recordset.total;
          this.orderLogs.page = response.data.recordset.page;
          this.finished = Math.ceil(this.orderLogs.total / rows) <= page;
          if (cb !== undefined)
            cb();
        }
      });
    },
    onLoad () {
      if (!this.loading) {
        this.loading = !this.loading;
        this.LoadOrderlogs(++this.page, () => {
          setTimeout(() => {
            this.loading = false;
          }, 1000)
        })
      }
    },
    submit () {
      let formData = new FormData();
      formData.append("group", 'appeals');
      this.imgVlaue.forEach((item) => {
        formData.append('files', item);
      })
      var url = null;
      var thumbs = null;
      var request_id = this.guid();
      this.socket.send(JSON.stringify({ type: 'data', request_id: request_id, data: JSON.stringify({ content: this.text, pictures: url, thumbs: thumbs, id: this.$route.query.id }) }));
      var value = {
        content: this.text,
        thumbs: thumbs,
        created_at: Math.ceil((new Date()).getTime() / 1000),
        request_id: request_id,
        ack: false,
        has_error: false
      }
      this.orderLogs.rows.push(value)
      this.time_replace(request_id);
      this.text = '';
      this.$refs.textarea.style.height = '40px';
      setTimeout(function () {
        var top = document.getElementById('detail');
        top.scrollTop = top.scrollHeight;
      }, 500)
      this.color = 'background:#8c8c8c;pointer-events: none';
    },

    write_num () {
      if (this.text.length > 0) {
        this.color = 'background: linear-gradient(310deg, #03F8B0 0%, #03A9B2 100%);pointer-events: auto'
      } else {
        this.color = 'background:#8c8c8c;pointer-events: none'
      }
      this.$refs.textarea.addEventListener('input', (e) => {
        this.$refs.textarea.style.height = '40px';
        this.$refs.textarea.style.height = e.target.scrollHeight + 'px';
      });
    },
    close_repair () {
      this.isSoved = true;
    },
    time_replace (request_id) {
      this.timer = setTimeout(() => {
        //需要定时执行的代码
        this.orderLogs.rows.forEach((item) => {
          if (item.request_id === request_id) {
            item.has_error = true;
          }
        })
      }, 10000)
    },
    bigUri (type, uri) {
      if (type === 'user') {
        this.userBig = uri;
        this.showMask = true;
        this.bigPic = true;
      } else {
        this.customerBig = uri;
        this.showMask = true;
        this.bigPicCustome = true;
      }
    },
    mask_close () {
      this.bigPic = false;
      this.bigPicCustome = false;
      this.showMask = false;
    }
  }

}
</script>

<style scoped>
</style>