import {createApp} from 'vue'
import App from './App.vue'
import {createI18n} from 'vue-i18n'
import Vant from 'vant';
import 'vant/lib/index.css';
import router from './router'
import languages from './languages/languages'
import request from "./request/request";
import Member from "./member/member";

// request.setAPI('http://192.168.50.93:8090/api/');
request.setAPI('https://api.initap.com/api/');

// localStorage.setItem('user-token','eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJJRCI6IjgwMDAxMCIsIkRhdGEiOiJ7XCJkZXZpY2VfaWRcIjpcIjFcIixcInBsYXRmb3JtXCI6XCJwY1wiLFwibGFzdF9sb2dpbl9hdFwiOjE2NzAzMTQ0MzAsXCJyZXF1ZXN0X2lkXCI6XCJlYTQ0NjY5ZC0xYzNiLTc2NjMtOThhZS00NWMyNzA0NzhmN2RcIixcImlzX3NwZWNpYWxcIjpmYWxzZSxcImlwXCI6XCJcIn0iLCJleHAiOjE2ODU4NjY0MzB9.QV-ieLUUoKg9qbilfxfKEfuy2oHMsIethNST-hOnI6I')


const i18n = createI18n(languages);
i18n.global.locale = navigator.language;

const app = createApp(App).use(i18n).use(Vant);

app.config.globalProperties.$member = new Member();
app.config.globalProperties.$request = request;


router.beforeEach((to, from, next) => {
    if (Object.prototype.hasOwnProperty.call(to.query, 'token')) {
        app.config.globalProperties.$member.setToken(to.query.token);
    }
    app.config.globalProperties.$member.load();
    window.document.title = to.meta.title === undefined ? '默认标题' : i18n.global.t(to.meta.title);
    //document.querySelector('meta[name="keywords"]').setAttribute('content', i18n.global.t(to.meta.keywords))
    document.getElementById('main-title').innerText = to.meta['title'] === undefined ? '默认标题' : i18n.global.t(to.meta.title);

    next();
});
app.use(router).mount('#app');